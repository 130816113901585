<template>
  <!-- 我的题单   -->
  <div class="">
   <el-tabs v-if="!$attrs.isCollect" v-model="activeName" @tab-click="getFormList(1,where=(curTabList[curTabList.map(d=>d.value).indexOf(activeName)]||{}).params || {})">
	 <el-tab-pane :label="tab.name" :name="tab.value"  v-for="(tab , index) in curTabList" :key="index"></el-tab-pane>
   </el-tabs>
    <div class="box">
      <com-table :columns="columns"
                 v-loading="loading"
                 ref="singleTable"
                 :data="tableData"
                 highlight-current-row
                 @current-change="handleCurrentChange"
                 @row-click="rowClick"
                 :page-options="false"
      >

        <template slot="from_title" slot-scope="{row}">
          <div class="source" :style="{ background: row.from_color }" v-if="row.from_title">
            {{ row.from_title }}
          </div>
        </template>
        <template slot="diff_title" slot-scope="scope">
          <div class="difficulty" :style="{ background: scope.row.diff_color }" v-if="scope.row.diff_title">
            {{ scope.row.diff_title }}
          </div>
        </template>
        <template slot="download" slot-scope="scope">
          <div class="img" @click.stop="download(scope.row, scope.$index)">
            <img src="@/assets/images/me/download@3x.png" style="position: relative;top:-3px;" alt="" />
          </div>
        </template>
        <template slot="action1" slot-scope="scope">
          <el-button class="details_btn" :disabled="!scope.row.log_id" @click.stop="look_details(scope.row)" >查看详情</el-button>
        </template>
        <template slot="action2" slot-scope="scope">
          <template v-if="$attrs.isCollect">
            <i @click.stop="meCollect(scope.row.id, scope.$index)"  class="el-icon-star-on" style="color: #f15153; font-size: 22px"></i>
          </template>
          <template v-else>
            <el-button icon="el-icon-delete-solid" :disabled="scope.row.user_id != userInfo.id" style="font-size: 20px;border: 0;padding: 0;"  @click.stop="deleteRow(scope.row.id, scope.$index)"></el-button>
          </template>
        </template>
      </com-table>
    </div>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination background layout="total,sizes,prev, pager, next" :total="total" @current-change="getFormList" @size-change="onSizeChange" :page-size="pageSize" :current-page="page">
      </el-pagination>
    </div>
    <diy-dialog class="order-payment-dialog" :bodyStyle="{'padding-top':0}" ref="orderPayment" width="500px" :showFooter="false">
      <order-sure type="base" @paySuccess="successPay" :detail="{...currentData,showResult:false}" :order_id="currentData.id"></order-sure>
    </diy-dialog>
  </div>
</template>

<script>
import { options_list, remove, meCollect, formCollect, my_list } from "@/api/me.js"
import { orderCreate } from "@/api/common.js"
import OrderSure from "@/views/api/com/orderSure";
export default {
  props: {
    student_user_id:{
      default: () => 0,
    }
  },
  //import引入的组件需要注入到对象中才能使用
  components: {OrderSure},

  data () {
    //这里存放数据
    return {
	  currentData:{},
      loading:false,
      price:'',
      order_num:'',
      mobile:'',
      color: "#F15153",
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      where:{},
      activeName:'all',
      tabList:[
        {name:"全部",value:'all',params:{}},
        {name:"我创建的",value:'create',params:{match_type:1}},
        {name:"我提交的",value:'submit',params:{match_type:2}},
      ],
      curTabList:[],
    }
  },
  computed:{
    columns(){
      return [
            {align:'center',label:'题单号',fixed:'left',prop:'id',width:80},
            {align:'center',label:'题单标题',prop:'title',minWidth:200,template:({row})=>{return row.title}},
            {align:'center',label:'来源',prop:'from_title',width:120,slot:'from_title'},
            {align:'center',label:'难度',prop:'diff_title',width:100,slot: 'diff_title'},
            {align:'center',label:'下载',width:50,slot: 'download'},
            {align:'center',label:'创建时间',width:160,prop:'create_time_f',hidden:this.$attrs.isCollect},
            {align:'center',label:'提交时间',width:160,prop:'submit_time_f',hidden:this.$attrs.isCollect},
            {align:'center',label:'得分/总分',width:120,prop: 'gradeStr',hidden:this.$attrs.isCollect},
            {align:'center',label:'答题详情',width:90,slot: 'action1',fixed:'right',hidden:this.$attrs.isCollect || !this.isLigonUser},
            {align:'center',label:'操作',width:50,fixed:'right',slot: 'action2',hidden:this.$attrs.isCollect || (this.$attrs.isCollect && this.userInfo.type+'' !== '2') || !this.isLigonUser},
      ];
    },
    isLigonUser(){
      return !(this.student_user_id && this.student_user_id+'' !== this.userInfo.id+'');
    },
  },
  mounted () {
    // 我的题单
    this.getFormList();
  },
  //方法集合
  methods: {
    userInfoListener(){
      this.routeUpdate();
    },
    routeUpdate(){
      if(this.student_user_id && this.student_user_id+'' !== this.userInfo.id+''){
        this.curTabList = this.tabList.map((d)=>{
          d.name = d.name.replace('我' , '他')
          return d;
        });
      }else{
        this.curTabList = [...this.tabList]
      }
    },
    look_details(item){
      this.$router.push({ path: '/problemList/tiDanRecord', query: { log_id: item.log_id} })
    },
    //支付成功
    successPay () {
      this.$refs.orderPayment.visibled(false);
	  var index = this.currentData.$index;
	  this.tableData[index].is_buy = 1;
	  this.download(this.tableData[index] , index);
    },
    // 我的提单
    getFormList (page) {
      this.page = page || 1;
      this.loading = true;
      if (this.$attrs.isCollect) {
        formCollect({ page: this.page, page_size: this.pageSize,student_user_id:this.student_user_id })
          .then(({ data }) => {
            this.tableData = data.data
            this.total = data.total
          }).finally(() => {
            this.loading = false
          })
      } else {
        my_list({
          page: this.page,
          page_size: this.pageSize,
          student_user_id: this.student_user_id,
		  ...this.where
        }).then(({data}) => {
          this.tableData = data.data
          this.total = data.total
        }).finally(() => {
          this.loading = false
        })
      }
    },
    onSizeChange(pagesize){
      this.pageSize=pagesize
      this.getFormList();

    },
    // 下载
    download (e,$index) {
      if (e.is_buy+'' === '1') {
        this.$tool.fileResponse(e.down_url)
      }else{
        orderCreate({
          type: 3,
          id: e.id,
        }).then((res) => {
          //已支付
          if (res.data.is_pay == 1) {
            this.$message({
              message: "支付成功",
              type: "success",
            })

          } else {
            this.currentData= res.data.order;
            this.currentData.$index= $index;
            this.$refs.orderPayment.visibled(true);
          }
        })
        
      }
    },
    // 删除题单
    deleteRow (id, index) {
      remove({ id: id })
        .then(({ data }) => {
          console.log(data, "删除题单成功")
          this.$message.success("删除成功")
          this.getFormList()
        })
        .catch((err) => {
          console.log(err, "删除题单失败")
        })
      console.log(id, index)
    },
    // 收藏
    meCollect (id, index) {
      console.log(id, index)
      meCollect({ id: id, type: 2 })
        .then((res) => {
          // console.log(data,'删除成功');
          this.$message.success(res.msg)
          this.getFormList()
          // this.tableData(index).is_collection=true
        })
        .catch((err) => { })
    },

    setCurrent (row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
    rowClick (e) {
     console.log(e.log_id,'e.log_id')
      let query = { id: e.id,is_buy:e.is_buy}
      if(this.student_user_id){
          query.log_id=e.log_id
      }
      this.$router.push({
        path: "/problemList/student/problemListDetails",
        query,
      })
    },
    handleCurrentChange (val) {
      this.currentRow = val
    },
  },
};
</script>
<style  lang='less' scoped>
  .ProblemList-name {
    color: #00957e;
  }
::v-deep {
  .el-table td {
    // padding: 0;
  }
}
.box {
  .details_btn {
	  // width: 80px;
	  padding: 0 4px;
	  border-radius: 2px;
	  height: 25px;
  }
  .details_btn:not(.is-disabled) {
	  background: #00957e;
	  color: #fff;
  }
  .el-button:not(.is-disabled) i{
	color: #414141; 
  }
  border: 1px solid #979797;
  .img {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .difficulty {
    width: 49px;
    height: 23px;
    border-radius: 2px;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
  }
  .source {
    width: 93px;
    height: 23px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    margin: 0 auto;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #01957e;
  }
  .code {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363636;
    line-height: 20px;
  }
  .result {
    .resultItem {
      display: flex;
      justify-content: center;
      div:nth-child(1) {
        margin-right: 10px;
      }
      div {
        padding: 0 8px;

        height: 30px;
        line-height: 30px;

        border-radius: 6px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>