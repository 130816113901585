<template>
  <div :class="'order-class order-class-' + type" v-loading="loading">
    <template v-if="resultData.success || detail.showResult === false">
      <slot v-bind:order="orderDetail" v-bind:payData="payData">
        <template v-if="type === 'base'">
          <div>
            <h3 style="margin-bottom: 10px">订单信息</h3>
            <div>
              <el-row>
                <el-col :span="14">
                  <p>订单编号：{{ orderDetail.order_num }}</p>
                </el-col>
                <el-col :span="10">
                  <p>订单价格：{{ orderDetail.price }}元</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <h3 style="margin-bottom: 10px">确认支付方式</h3>
            <form-create
              label-width="90px"
              v-model="payFormData"
              :components="[
                {
                  name: 'form-item',
                  props: {
                    prop: 'mobile',
                    label: '手机号',
                    placeholder: '请输入手机号',
                    maxlength: 11,
                    style: 'width:50%;',
                  },
                },
                {
                  name: 'form-item',
                  props: {
                    prop: 'pay_type',
                    label: '支付方式',
                    type: 'radio',
                    data: payData,
                  },
                  slots: { content: 'pay_type' },
                },
                {
                  name: 'form-item',
                  props: {
                    prop: 'remark',
                    label: '我要留言',
                    placeholder: '请输入内容',
                    type: 'textarea',
                    rows: 4,
                    maxlength: 300,
                    showWordLimit: true,
                  },
                },
              ]"
            >
              <template slot="pay_type" slot-scope="pay">
                <el-image :src="pay.icon"></el-image>
              </template>
            </form-create>
          </div>
        </template>
        <el-card class="know-list" v-else>
          <div slot="header" class="clearfix" style="display: flex">
            <el-image
              :src="require('@/assets/images/problemList/标签@2x.png')"
              style="width: 24px; height: 24px"
            ></el-image>
            <span style="color: #00957e">确认订单信息</span>
          </div>
          <div style="display: flex">
            <el-image
              style="width: 60px; height: 60px; border-radius: 50%"
              :src="orderDetail.user_headimg"
              fit="cover"
            ></el-image>
            <span
              style="
                line-height: 60px;
                display: inline-block;
                margin-left: 20px;
              "
              >购买账号：{{ orderDetail.user_realname }}({{
                orderDetail.user_mobile
              }})</span
            >
          </div>
          <div style="margin: 20px 30px" v-if="orderDetail.detail && orderDetail.type==2">
            <h3 style="margin-bottom: 10px;">支付信息</h3>
            <div style="margin-bottom: 10px;font-size: 14px;">购买课程：<span style="font-weight: bold;">{{ orderDetail.detail.course_name}}</span></div>
            <div style="margin-bottom: 10px;font-size: 14px;">主讲人：{{ orderDetail.detail.course_name}}</div>
            <div style="font-size: 14px;">课时：{{ orderDetail.detail.class_hour}}</div>
          </div>
          <div style="margin: 20px 30px" v-if="orderDetail.detail && orderDetail.type==5">
            <h3 style="margin-bottom: 10px;">支付信息</h3>
            <div style="margin-bottom: 10px;font-size: 14px;">课程：<span style="font-weight: bold;">{{ orderDetail.detail.course_name}}</span></div>
            <div style="margin-bottom: 10px;font-size: 14px;">费用：<span style="color: #f00606;">{{ orderDetail.price}}</span></div>
          </div>
          <h3 style="margin: 20px 30px">支付方式</h3>
          <div style="padding: 20px; background-color: #f3f8f9">
            <!-- <el-collapse accordion v-model="collapseOpen">
              <el-collapse-item name="1">
                <div slot="title"  style="display: flex;position:relative;width: 100%;">
                  <el-image
                      :src="(payData.filter(d=>d.value === payFormData.pay_type)[0] || {}).icon"
                  ></el-image>
                  <span style="display: inline-block;margin-left: 20px;">{{ (payData.filter(d=>d.value === payFormData.pay_type)[0] || {}).name }}</span>
                  <div style="position: absolute;right:0;">
                    <el-button type="text" @click="collapseOpen = '1'" v-if="collapseOpen">
                      <span style="font-size: 14px;margin-right: 5px;color: #808080;">收起</span>
                    </el-button>
                  </div>
                </div>
                <div>
                  <div style="background-color: #DCDFE6;height:1px;width:100%;margin: 15px 0 ;"></div>
                  <div :style="(payIndex>0?'margin-top:20px;':'')" v-for="(pay,payIndex) in payData" :key="payIndex">
                    <el-radio v-model="payFormData.pay_type" :label="pay.value">
                      <div style="display:flex;">
                        <el-image
                            :src="pay.icon"
                        ></el-image>
                        <span style="display: inline-block;margin-left: 20px;">{{ pay.name }}</span>
                      </div>
                    </el-radio>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse> -->
            <div>
              <div
                :style="payIndex > 0 ? 'margin-top:20px;' : ''"
                v-for="(pay, payIndex) in payData"
                :key="payIndex"
              >
                <el-radio v-model="payFormData.pay_type" :label="pay.value">
                  <div style="display: flex">
                    <el-image :src="pay.icon"></el-image>
                    <span style="display: inline-block; margin-left: 20px">{{
                      pay.name
                    }}</span>
                  </div>
                </el-radio>
              </div>
            </div>
          </div>
          <div v-if="orderDetail.detail && orderDetail.detail.is_plan == 1">
            <h3 style="margin: 20px 30px">购买教案</h3>
            <div style="display: flex">
              <el-image
                style="height: 101px; width: 196px"
                :src="(orderDetail.detail || {}).cover"
              ></el-image>
              <div
                style="
                  flex: 1;
                  padding: 0 20px;
                  position: relative;
                  height: 101px;
                "
              >
                <div class="shenluehao" style="font-weight: bold">
                  {{ (orderDetail.detail || {}).course_name }}
                </div>

                <div
                  class="shenluehao"
                  style="font-weight: bold; margin-top: 18px"
                >
                  {{
                    orderDetail.params && orderDetail.params.buy_type == 1
                      ? "普通许可购买"
                      : "高级许可购买"
                  }}
                </div>

                <div class="shenluehao" style="margin-top: 14px">
                  普通许可价格<span style="color: #f00606; font-size: 14px"
                    >¥{{ orderDetail.detail && orderDetail.detail.price }}</span
                  >
                  高级许可价格<span style="color: #f00606; font-size: 14px"
                    >¥{{
                      orderDetail.detail && orderDetail.detail.price2
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </slot>

      <slot name="footer" v-bind:order="orderDetail" v-bind:payData="payData">
        <div style="text-align: right; margin-top: 20px" v-if="type === 'base'">
          <el-button size="small" type="primary" @click="handleCommand('buy')"
            >确认支付</el-button
          >
        </div>
        <el-card
          style="margin-top: 40px"
          :body-style="{ textAlign: 'right' }"
          v-else
        >
          <div class="price">
            实付：<span style="color: #f00606; font-size: 22px"
              >¥{{ orderDetail.price }}</span
            >
          </div>
          <div style="margin-top: 20px">
            提交订单则表示您同意<span
              class="agreement"
              @click="
                $handleRoute(
                  { type: 'user_agreement', title: '垦科鼎课堂用户服务协议' },
                  'agreement',
                  '_blank'
                )
              "
              >《垦科鼎课堂用户服务协议》</span
            ><span
              style="margin-left: -4px"
              class="agreement"
              @click="
                $handleRoute(
                  { type: 'privacy_policy', title: '垦科鼎课堂售后政策' },
                  'agreement',
                  '_blank'
                )
              "
              >《垦科鼎课堂售后政策》</span
            >等
            <el-button type="primary" @click="handleCommand('buy')"
              >立即支付</el-button
            >
          </div>
        </el-card>
      </slot>
      <diy-dialog
        class="order-pay-dialog"
        width="300px"
        center
        :showFooter="false"
        ref="payDialog"
        :title="'扫一扫付款(元)'"
      >
        <div style="text-align: center">
          <div class="price">
            <span style="color: #f00606; font-size: 22px"
              >¥{{ orderDetail.price }}</span
            >
          </div>
          <div
            style="
              height: 200px;
              width: 200px;
              position: relative;
              display: inline-block;
            "
          >
            <el-image
              style="height: 100%; width: 100%"
              :src="payDatabases.pay_img"
            ></el-image>
            <div
              style="
                height: 20px;
                width: 20px;
                position: absolute;
                top: 87px;
                left: 87px;
                border-radius: 2px;
                padding: 3px;
                background-color: #fff;
              "
            >
              <el-image
                style="height: 20px; width: 20px"
                :src="
                  (
                    payData.filter(
                      (d) => d.value + '' === payFormData.pay_type + ''
                    )[0] || {}
                  ).icon
                "
              ></el-image>
            </div>
          </div>
          <div
            style="
              display: flex;
              margin-top: 10px;
              justify-content: center;
              align-items: center;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 fulGNA svg-icon-path-icon fill"
              style="fill: rgb(254, 173, 34)"
              viewBox="64 64 896 896"
              width="60"
              height="60"
            >
              <defs data-reactroot=""></defs>
              <g>
                <path
                  d="M136 384h56c4.4 0 8-3.6 8-8V200h176c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H196c-37.6 0-68 30.4-68 68v180c0 4.4 3.6 8 8 8zm512-184h176v176c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V196c0-37.6-30.4-68-68-68H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zM376 824H200V648c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v180c0 37.6 30.4 68 68 68h180c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm512-184h-56c-4.4 0-8 3.6-8 8v176H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h180c37.6 0 68-30.4 68-68V648c0-4.4-3.6-8-8-8zm16-164H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"
                ></path>
              </g>
            </svg>
            <div
              style="
                display: inline-block;
                line-height: 30px;
                margin-left: 20px;
                font-size: 18px;
                color: #303133;
                text-align: left;
              "
            >
              <p>
                打开手机{{
                  (
                    payData.filter(
                      (d) => d.value + "" === payFormData.pay_type + ""
                    )[0] || {}
                  ).name
                }}
              </p>
              <p>扫一扫继续付款</p>
            </div>
          </div>
        </div>
      </diy-dialog>
    </template>

    <el-result
      icon="error"
      :title="resultData.title || '信息提示'"
      :subTitle="resultData.message"
      v-else
    >
      <template slot="extra">
        <el-button
          type="primary"
          size="medium"
          @click="$routerGo(-1)"
          v-if="type === 'base'"
          >关闭</el-button
        >
        <el-button type="primary" size="medium" @click="$routerGo(-1)" v-else
          >返回上一页</el-button
        >
      </template>
    </el-result>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["order_id", "type", "detail", "status"],
  data() {
    return {
      resultData: { success: true, message: "", title: "" },
      collapseOpen: "1",
      orderDetail: {},

      payFormData: {
        pay_type: 2,
      },
      payData: [
        {
          icon: require("@/assets/images/me/zfb.png"),
          name: "支付宝",
          value: 2,
        },
        { icon: require("@/assets/images/me/wx.png"), name: "微信", value: 1 },
      ],
      payDatabases: {},
      timer: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(["currentCrumbList"]),
  },
  watch: {
    order_id(v, o) {
      if (v !== o) {
        this.orderDetail = this.detail || {};
        this.payFormData.mobile =
          this.payFormData.mobile || this.orderDetail.mobile;
        this.getDetail();
      }
    },
  },
  mounted() {
    // setTimeout(()=>{this.routeNavigateBack()},2000)
  },
  beforeDestroy() {
    this.orderDetail = null;
    // this.$socket.delListen("/xapi/com.order/query/id/" + this.order_id);
    this.clearTimer();
  },
  methods: {
    routeUpdate() {
      this.orderDetail = this.detail || {};
      this.payFormData.mobile =
        this.payFormData.mobile || this.orderDetail.mobile;
      this.getDetail();
    },
    getDetail() {
      if (this.order_id > 0) {
        this.loading = true;
        this.$http
          .post("/xapi/com.order/detail", { id: this.order_id })
          .then(({ data }) => {
            this.orderDetail = data || {};
            this.payFormData.mobile =
              this.payFormData.mobile || (data || {}).mobile;
            if (data.is_pay === true) {
              this.$handleMessage(
                "订单已支付！",
                "success",
                this.routeNavigateBack
              );
            } else {
              this.$socket.listen(
                "/xapi/com.order/query/id/" + this.order_id,
                {},
                ({ data }) => {
                  if (data.is_pay === 1 || data.is_pay === true) {
                    this.$handleMessage(
                      "订单已支付！",
                      "success",
                      this.routeNavigateBack
                    );
                  }
                }
              );
            }
          })
          .catch((error) => {
            this.clearTimer();
            this.resultData = { ...error, success: false };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.clearTimer();
        this.resultData = { success: false, message: "订单没找到！" };
      }
    },
    routeNavigateBack() {
      if (this.$listeners["paySuccess"]) {
        // 如果提供执行
        this.$emit("paySuccess");
      } else {
        var route = this.currentCrumbList[this.currentCrumbList.length - 2];
        this.$routerGo(-1, { ...route });
      }
    },
    clearTimer() {
      if (this.timer !== null) {
        this.$clearTimeout(this.timer);
        this.timer = null;
      }
    },
    addTimer() {
      this.timer = this.$setTimeout(this.orderQuery, 500);
    },
    orderQuery() {
      if (this.orderDetail === null) return;
      console.log(this.status, "this.statusthis.status");
      this.$http
        .post(
          "/xapi/com.order/query",
          { ...this.payFormData, id: this.order_id },
          { message: false }
        )
        .then(({ msg, data }) => {
          // this.$router.back()

          if (data.is_pay === 1 || data.is_pay === true) {
            this.clearTimer();
            this.$refs.payDialog.visibled(false);
            this.$handleMessage(msg || "支付成功！", "success", () => {
              console.log("扫码支付完成");
              // this.routeNavigateBack();
              if (this.status) {
                this.$emit("successPay");
              } else {
                this.$router.back();
              }
            });
          } else {
            this.addTimer();
          }
        })
        .catch((error) => {
          this.clearTimer();
          this.$alert(error, "错误提示", { close: this.routeNavigateBack });
        });
    },
    handleCommand(command) {
      if (command === "buy") {
        this.loading = true;
        this.clearTimer();
        this.$http
          .post("/xapi/com.order/pay", {
            ...this.payFormData,
            id: this.order_id,
          })
          .then(({ msg, data }) => {
            this.payDatabases = data || {};
            this.addTimer();
            this.$refs.payDialog.visibled(true);
            if (msg !== "ok")
              this.$handleMessage(msg, "success", () => {
                console.log("支付完成，关闭弹窗111111111111");
                this.routeNavigateBack();
                // this.$emit('successPay')
              });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
::v-deep {
  .el-radio {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .el-image {
    width: 41px;
    height: 41px;
  }
}
.order-class::v-deep:not(.order-class-base) {
  .el-collapse,
  .el-collapse-item__wrap,
  .el-collapse-item__header {
    border: none;
    background-color: initial;
  }
  .el-collapse .el-image {
    width: 41px;
    height: 41px;
  }
  .el-collapse-item__header {
    height: 41px;
  }
  .el-collapse-item__header,
  .el-collapse-item__content,
  .el-radio__input,
  .el-radio__label {
    line-height: 41px;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #303133 !important;
  }
}
.order-class-base::v-deep {
  .el-radio {
    .el-image {
      width: 40px;
      height: 40px;
    }
  }
  .el-radio:not(:last-child) {
    margin-bottom: 10px;
  }
}
.order-pay-dialog::v-deep .el-dialog__body {
  padding: 0 25px 30px;
}
.agreement {
  cursor: pointer;
  font-size: 14px;
  color: #00957e;
}
</style>